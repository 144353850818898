





































































































































































































































































import {
  Vue, Prop, Emit,
} from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import BaseBankIcon from '@/components/BaseBankIcon.vue';
import moment from 'moment';
import AuthConnector from '@/connector/Auth.vue';
import ProfileConnector from '@/connector/Profile.vue';
// eslint-disable-next-line import/no-cycle
import BankMixin from '@/mixins/bankMixin';
// eslint-disable-next-line import/no-cycle
import CreditTurnMixin from '@/mixins/creditTurnMixin';
// eslint-disable-next-line import/no-cycle
import TransactionMixin from '@/mixins/transactionMixin';

@Component({
  components: {
    BaseBankIcon,
    ProfileConnector,
    AuthConnector,
  },
})
// eslint-disable-next-line max-len
export default class Deposit extends Mixins<BankMixin & CreditTurnMixin & TransactionMixin>(BankMixin, CreditTurnMixin, TransactionMixin) {
  public openDepositForm: boolean = false;

  // eslint-disable-next-line camelcase
  public selectedBank: { bank: string; name: string; account_number: string } = {
    bank: '',
    name: '',
    account_number: '',
  };

  public amount: string = '';

  public slip: File|null = null;

  public date: string = this.addZeroPrefix(new Date().getDate());

  public month: string = this.addZeroPrefix(new Date().getMonth() + 1);

  public year: number = new Date().getFullYear();

  public hour: string = this.addZeroPrefix(new Date().getHours());

  public minute: string = this.addZeroPrefix(new Date().getMinutes());

  // eslint-disable-next-line class-methods-use-this
  addZeroPrefix(value: number): string {
    if (value < 10) {
      return `0${value}`;
    }
    return String(value);
  }

  // eslint-disable-next-line class-methods-use-this
  get days(): Array<string> {
    const endDate = Number(moment(`${this.year}-${this.month}`).endOf('month').format('D'));
    return Array.from({ length: endDate }, (_, i) => this.addZeroPrefix(i + 1));
  }

  // eslint-disable-next-line class-methods-use-this
  get months(): Array<{ text: string; value: string }> {
    return [
      { value: '01', text: 'มกราคม' },
      { value: '02', text: 'กุมภาพันธ์' },
      { value: '03', text: 'มีนาคม' },
      { value: '04', text: 'เมษายน' },
      { value: '05', text: 'พฤษภาคม' },
      { value: '06', text: 'มิถุนายน' },
      { value: '07', text: 'กรกฎาคม' },
      { value: '08', text: 'สิงหาคม' },
      { value: '09', text: 'กันยายน' },
      { value: '10', text: 'ตุลาคม' },
      { value: '11', text: 'พฤศจิกายน' },
      { value: '12', text: 'ธันวาคม' },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get years(): Array<number> {
    const currentYear = new Date().getFullYear();
    return [
      currentYear, currentYear - 1,
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get hours(): Array<string> {
    return Array.from({ length: 24 }, (_, i) => this.addZeroPrefix(i));
  }

  get minutes(): Array<string> {
    return Array.from({ length: 60 }, (_, i) => this.addZeroPrefix(i));
  }

  public async copyAccountNumber(id: string) {
    const copyText = document.getElementById(id);
    if (copyText) {
      const value = copyText?.innerText;
      navigator.clipboard.writeText(value);
      this.$store.commit('Snackbar/showNotification', { message: `คัดลอกเลขบัญชี ${value} แล้ว`, error: false });
    }
  }

  onSelectFile(file: File|null) {
    this.slip = file;
  }

  public getFormData(): FormData {
    const formData = new FormData();
    formData.append('to_bank', this.selectedBank.bank);
    formData.append('to_account_number', this.selectedBank.account_number);
    formData.append('amount', this.amount);
    const requestTime = `${this.year}-${this.month}-${this.date} ${this.hour}:${this.minute}`;
    formData.append('request_time', moment(requestTime).valueOf().toString());
    if (this.slip) {
      const { slip } = this;
      formData.append('slip', slip);
    }

    return formData;
  }

  public resetFormData(): void {
    this.selectedBank = {
      bank: '',
      name: '',
      account_number: '',
    };
    this.amount = '';
    this.slip = null;
    this.onSelectFile(null);
    this.date = this.addZeroPrefix(new Date().getDate());
    this.month = this.addZeroPrefix(new Date().getMonth() + 1);
    this.year = new Date().getFullYear();
    this.hour = this.addZeroPrefix(new Date().getHours());
    this.minute = this.addZeroPrefix(new Date().getMinutes());
  }
}
